import React, { useState, useEffect } from 'react';
import './Hsseaudits.css';
import audit from '../assets/audit.jpg';



const Hsseaudits = () => {

  return (
    <div className="section-consult"  id="hsse-audits">
        <div className="left-consult">
            <div className="dash-c"></div>
            <div className="section-consult-description">
                <h1>HSSE Audits</h1>
                <p>
                We conduct a full audit of your business and workplace to verify that your health and safety practices are correct. Our safety auditors can assist any companies that require health and safety audits of their management systems.
                <br/>
                <br/>
                Our audits are tailored to meet the specific needs of our clients both in Uganda and abroad. For smaller organizations, they can be as simple as an inspection of the premises and a review of current health and safety documentation. This is followed by a report detailing all observations and a corrective action plan (CAP).
                <br/>
                <br/>
                Larger firms may be keen to have external audits of their management systems. Our health and safety audits are conducted according to the requirements of Uganda Labour Legislation and the principles contained within the Occupational Health & Safety Act of 2006.
                <br/>
                <br/>

                After these assessments have been completed, we produce a prioritized action plan offering guidance to the health and safety committee on how to make any required improvements.
                </p>
            </div>    
        </div> 
      <div className="right-consult">
        <div className="consult-img-list">
            <img src={audit} alt='safety-consult' />


        </div>
        </div>
        
    </div>
  );
};

export default Hsseaudits;
