import React from 'react';
import './Whatsapp.css'; // Create a CSS file for styling
import whatsappImage from '../assets/whatsapp.png';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Handle the click event, e.g., open the WhatsApp chat
    window.location.href = 'https://wa.me/256752264385'; // Replace with your WhatsApp number
  };

  return (
    <div className="whatsapp-button" onClick={handleWhatsAppClick}>
      <img src={whatsappImage} alt="WhatsApp Logo" />
      <p>Talk to us</p>
    </div>
  );
};

export default WhatsAppButton;
