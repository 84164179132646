import React, { useState, useEffect } from 'react';
import './Consult.css';
import consult from '../assets/consult.jpg';



const Consult = () => {

  return (
    <div className="section-consult">
        <div className="left-consult">
            <div className="dash-c"></div>
            <div className="section-consult-description">
                <h1>Consultancy</h1>
                <p>
                We offer consultancy services for the following services,
                <ul>
                  <li>OSH Policy Formulation</li>
                  <li>Support Contracts</li>
                  <li>Work Place Inspections</li>
                  <li>Health and Safety Mini & Comprehensive Audits</li>
                  <li>Risk Assesments</li>
                  <li>Health and Safety Manuals</li>
                  <li>Emergency Preapredness and Response</li>
                  <li>Hazard and Disease Surveillance</li>
                  <li>Accident Investigations</li>
                  <li>Job Hazard Analysis</li>

                </ul>
                </p>
            </div>    
        </div> 
      <div className="right-consult">
        <div className="consult-img-list">
            <img src={consult} alt='safety-consult' />
        </div>
        </div>
        
    </div>
  );
};

export default Consult;
