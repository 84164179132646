// AboutUs.jsx

import React from 'react';
import './Aboutus.css'; // You can define your styles in a separate CSS file

const Aboutus = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <section>
          <h2>About Us</h2>
          
            DIOSH is a Ugandan Company Registered with the Uganda Registration Services Bureau with Registration Number: 80034318516304.
            <br/>
            <br/>
          
          <p>
            DIOSH provides businesses with the professional training, support, and knowledge needed to maintain compliance and ensure workplace safety. Our staff are dedicated to providing quality on-site consulting and training services to meet your specific needs and help you increase productivity, decrease risks, and minimize costs.
          </p>
          <p>
            We help you plan, develop, and execute to maintain occupational health and safety compliance. For those who already have OSHA programs in place, our occupational health and safety services can minimize any illnesses or injuries that may occur by systematically evaluating them. After our assessments by trained and certified occupational health consultants, we provide our recommendations on how well your current program is working and what could be improved.
          </p>
          <p>
            Health and safety at work encompass a wide range of duties and initiatives aimed at maintaining a safe working environment for employees. We can help your organization to implement relevant pieces of legislation and case law that impose legal duties on matters ranging from the provision of insurance and written policies on health and safety to education and training for staff, risk assessments, and inspections.
          </p>
          <p>
            It’s an Employer’s role to prevent the development of diseases, injuries & death which are caused by working conditions in offices & workplaces. You do not have to wait for compensation of workers for injuries suffered and scheduled diseases incurred in the course of their employment. Remove all possibilities where people can die as a result of occupational accidents or work-related diseases.
          </p>
          <p>
            DIOSH is providing advisory services and training to create awareness of the dimensions and consequences of work-related accidents, injuries, and diseases. We can work with you to develop an OSH Policy, set OSH Objectives and Targets, form a committee responsible for Health & safety, and prepare an OSH Manual that will guide the organization to have its own capability assessment. In addition, we can also prepare Workplace Assessment/Inspection Checklist and a corrective action plan that captures Recommendations (Identified Hazards / OSH System Deficiencies)
          </p>
        </section>

        <section>
          <h2>Our Vision</h2>
          <p>
            To be a leading provider of OSH services and promoting healthy and safe workplaces in Uganda and beyond based on inclusive participation in the development of an OSH risk prevention culture to ensure smart, sustainable and productive workplaces.
          </p>
        </section>

        <section>
          <h2>Our Mission</h2>
          <p>
            We develop, train and provide relevant information, analysis and tools to advance knowledge, raise awareness and exchange occupational safety and health information and good practice which will serve the needs of those involved in OSH.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Aboutus;
