// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './navbar/Navbar'; // Adjust the path based on your project structure
import Header from './header/Header';
import Ads from './ads/Ads'

import Services from './services/Services';
import Hssetraining from './hssetraining/Hssetraining';
import Policy from './policy/Policy';
import Consult from './consult/Consult';
import Contracts from './contracts/Contracts';
import Workplace from './workplace/Workplace';
import Hsseaudits from './hsseaudits/Hsseaudits';
import Risk from './risk/Risk';
import Manuals from './manuals/Manuals';
import Emergency from './emergency/Emergency';
import Clients from './clients/Clients';
import Aboutus from './aboutus/Aboutus';
import Recruitment from './recruitment/Recruitment';

import Footer from './footer/Footer';
import Whatsapp from './whatsapp/Whatsapp';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
       
        <Routes>
          
        <Route path="/services" element={<Services />} />
        <Route path="/training" element={<Hssetraining />} />
        <Route path="/consultancy" element={<Consult />} />
        <Route path="/aboutus" element={<Aboutus/>} />
        <Route path="/contact" element={<Footer/>} />
        <Route path="/recruitment" element={<Recruitment/>}/>
          
        </Routes>
    
        <Header/>

        <Services/>
        <Hssetraining/>
        <Consult />
        <Policy/>
        <Contracts/>
        <Workplace/>
        <Hsseaudits/>
        <Risk/>
        <Manuals/>
        <Emergency/>
        <Clients/>
        
       
        <Footer/>
        <Whatsapp/>

      
      </div>
    </Router>
  );
};

export default App;
