import React from 'react';
import './Footer.css';
import locationImage from '../assets/location.png'; // Replace with your actual image
import emailImage from '../assets/email.png'; // Replace with your actual image
import phoneImage from '../assets/dial.png'; // Replace with your actual image
import facebookImage from '../assets/facebook.png';
import linkedinImage from '../assets/linkedin.png';

const Footer = () => {
  // Sample data
  const services = ['Service 1', 'Service 2', 'Service 3'];
  const location = ['Plot 22, ', 'One Price Building, ', '  2rd Floor,  Suite No. B6',' Opposite Colline Hotel.',' Jinja Road,', '   Mukono.'];
  const emails = ['taremwadyson@gmail.com', 'dioshconsults@gmail.com'];
  const phoneNumbers = ['+256-752264385', '+256-786572529'];
  const currentYear = new Date().getFullYear();

  return (
    <div className='main-footer'>

      <div className="contact-section">
        <div>
          <h3>Find us at</h3>
          <img src={locationImage} alt="Location" />
          <p>{location}</p>
        </div>

        <div>
          <h4>Emails</h4>
          <img src={emailImage} alt="Email" />
          <ul>
            {emails.map((email, index) => (
              <li key={index}>
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h4>Phone Numbers</h4>
          <img src={phoneImage} alt="Phone" />
          <ul>
            {phoneNumbers.map((phoneNumber, index) => (
              <li key={index}>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/*<div className = "Social_media-links">
      <h3>Follow us on</h3>
          <img src={facebookImage} alt="facebook" />
          <img src={linkedinImage} alt="Linkedin" />
    
          
            </div>*/}

      <div className="copyright-section">
        <p>&copy; {currentYear} Diosh. All rights reserved.</p>
        <p>Developed by Cloud Media</p>
      </div>
    </div>
  );
};

export default Footer;
