import React, { useState, useEffect } from 'react';
import './Workplace.css';
import inspection from '../assets/inspection.jpg';


const Policy = () => {
      return (
        <div className="section-osh" id="workplace-inspections">

          <div className="right-osh">
            <div className="section-osh-imglist">
              <img src={inspection} alt='osh' />
 
            </div>
          </div>
          <div className="left-osh">
            <div className="dash-y"></div>
            <div className="section-osh-description">
              <h1>Workplace Inspections</h1>
              <p>
              We carry out thorough workplace inspections and provide practical advice on achieving compliance.
              <br/>
              Health and safety inspections are a necessary part of any organization. Performing thorough checks regularly allows the identification and control of potential hazards and ensure employees and other individuals are not put at risk.
              <br/>
              <br/>
              Some workplaces like construction sites can be hazardous environments, so to mitigate risk, safety inspections can be combined with toolbox talks.
              <br/>
              <br/>
              
              These talks provide additional evidence that an employer is fulfilling legal obligations to give employees and subcontractors information and training to protect their wellbeing.
              <br/>
              Inspection reports can be submitted electronically or can be handwritten and issued on site. Our inspection service can also be covered as part of our annual support contracts.
              </p>
            </div>
          </div>
        </div>
      );
    };
    

export default Policy;
