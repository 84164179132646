import React, { useState, useEffect } from 'react';
import './Manuals.css';
import manual from '../assets/manual.jpg';



const Manuals = () => {

  return (
    <div className="section-consult" id="hsse-manuals">
        <div className="left-consult">
            <div className="dash-c"></div>
            <div className="section-consult-description">
                <h1>HSSE Manuals</h1>
                <p>
                We work closely with your team to create a tailored health and safety manual. Your company must have written arrangements in place for the planning, organising, controlling, monitoring, and Reviewing of health and safety measures
                <br/>
                <br/>
                As part of this, firms must have documents in place to ensure that their personnel is informed and up-to-date concerning the relevant safety information.
                </p>
            </div>    
        </div> 
      <div className="right-consult">
        <div className="consult-img-list">
            <img src={manual} alt='safety-consult' />


        </div>
        </div>
        
    </div>
  );
};

export default Manuals;
