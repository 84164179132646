// HeroSection.js

import React from 'react';
import './Header.css'; // Import your styles for the hero section

const handleWhatsAppClick = () => {
  // Handle the click event, e.g., open the WhatsApp chat
  window.location.href = 'https://wa.me/qr/SFK35KCD6DW7G1'; // Replace with your WhatsApp number
};


const Header = () => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>DIOSH<br/> <br/>Environmental, Occupational Health, and Safety 
          <br/>Compliance Consultants</h1>
        <p>We are the leading providers of Health and Safety services aimed at<br/> promoting healthier and safe working Environment in Uganda and beyond.</p>
        <button  onClick={handleWhatsAppClick}> Enquire</button>
      </div>
    </div>
  );
};

export default Header;
