import React, { useState, useEffect } from 'react';
import './Recruitment.css';
import recruitment from '../assets/recruitment.jpg';



const Consult = () => {

  return (
    <div className="section-consult" id="support-contracts">
        <div className="left-consult">
            <div className="dash-c"></div>
            <div className="section-consult-description">
                <h1>Recruitment Services</h1>
                <p>
                DIOSH Environmental, Occupational, Health and Safety Consult provides premier recruitment services for companies seeking highly skilled health and safety officers. 
                <br/>
                <br/>
                Our commitment to professionalism, integrity, and excellence ensures fairness, transparency, and equal opportunity for all candidates. We embrace diversity and guarantee confidentiality, handling sensitive information with utmost discretion. 
                <br/>
                <br/>
                Upholding the highest ethical standards, we communicate transparently and comply with all relevant laws and regulations. Through continuous improvement, we strive to enhance client satisfaction and candidate experience. Join us at DIOSH to elevate your talent acquisition process and drive success for your organization.
                </p>
            </div>    
        </div> 
      <div className="right-consult">
        <div className="consult-img-list">
            <img src={recruitment} alt='recruitment' />


        </div>
        </div>
        
    </div>
  );
};

export default Consult;
