import React, { useState, useEffect } from 'react';
import './Clients.css'; // Create a CSS file for styling
import gov from '../assets/gov.jpeg';
import scoul from '../assets/scoul.png';
import hygien from '../assets/hygien.png';
import mtac from '../assets/mtac.jpg';
import hill from '../assets/hill.png';


const Clients = () => {
  // Sample client logos
  const clientLogos = [
   
    hygien,
    scoul,
    mtac,
    hill,
    gov,
    gov,
    gov,
    // Add more logos as needed
  ];

  const [visibleLogos, setVisibleLogos] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Function to update the visible logos array based on the current index
    const updateVisibleLogos = () => {
      const endIndex = index + 4 > clientLogos.length ? clientLogos.length : index + 4;
      setVisibleLogos(clientLogos.slice(index, endIndex));
    };

    updateVisibleLogos(); // Initial update

    // Set up an interval to cycle through logos
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 4 < clientLogos.length ? prevIndex + 4 : 0));
      updateVisibleLogos();
    }, 5000); // Change logos every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [index, clientLogos]);

  return (
    <div className="our-clients">
    <h1>Our Clients</h1>
    
    <div className="client-logos">
      {visibleLogos.map((logo, logoIndex) => (
        <img key={logoIndex} src={logo} alt={`Client Logo ${logoIndex + 1}`} />
      ))}
    </div>
    </div>
  );
};

export default Clients;
