import React, { useState, useEffect } from 'react';
import './Risk.css';
import risk from '../assets/risk.jpg';


const Risk = () => {
      return (
        <div className="section-osh" id="risk-assessments">

          <div className="right-osh">
            <div className="section-osh-imglist">
              <img src={risk} alt='osh' />

            </div>
          </div>
          <div className="left-osh">
            <div className="dash-y"></div>
            <div className="section-osh-description">
              <h1>Risk Assesments</h1>
              <p>
              DIOSH can carry out risk assessments for  your business to identify hazards and risks, propose control measures and the teams responsible for minimizing the level of these risks so as to creat a healthier and safer workplace 
              </p>
            </div>
          </div>
        </div>
      );
    };
    

export default Risk;
