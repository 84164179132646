import React, { useState, useEffect } from 'react';
import './Contracts.css';
import headerimg from '../assets/headerimg.png';



const Consult = () => {

  return (
    <div className="section-consult" id="support-contracts">
        <div className="left-consult">
            <div className="dash-c"></div>
            <div className="section-consult-description">
                <h1>Support Contracts</h1>
                <p>
                We can assume the role of your ‘competent health and safety personnel to ensure that you comply with the requirements of the law.
                <br/>
                In addition to our ad-hoc services, we also offer annual Health & Safety Support Contracts that are tailored to meet the needs of our clients.
                <br/>
                <br/>
                If you sign up for one of these contracts, we will assume the role of your ‘competent health & safety advisor’, as required by Ugandan law. When tendering for contracts, you can use details of our qualifications and experience. 
                <br/>
                <br/>
                We can also assist with pre-qualification questionnaires, whether these are for specific clients.
                </p>
            </div>    
        </div> 
      <div className="right-consult">
        <div className="consult-img-list">
            <img src={headerimg} alt='safety-consult' />


        </div>
        </div>
        
    </div>
  );
};

export default Consult;
