import React, { useState, useEffect } from 'react';
import './Policy.css';
import policy from '../assets/policy.jpg';


const Policy = () => {
      return (
        <div className="section-osh" id="osh-policy">

          <div className="right-osh">
            <div className="section-osh-imglist">
              <img src={policy} alt='osh' />


            </div>
          </div>
          <div className="left-osh">
            <div className="dash-y"></div>
            <div className="section-osh-description">
              <h1>OSH Policy Formulation</h1>
              <p>
              We will create and write your health and safety policy, or review an existing one to ensure it is up-to-date and appropriate.
              <br/>
              <br/>
              Policies provide guidance and instructions on how to do tasks ,creats confidence and reduces bias in decision making thereby protecting staff from acting in a manner that might endanger their employment.
             
              </p>
            </div>
          </div>
        </div>
      );
    };
    

export default Policy;
