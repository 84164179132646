import React, { useState, useEffect } from 'react';
import './Emergency.css';
import emergency from '../assets/emergency.jpg';



const Manuals = () => {

  return (
    <div className="section-consult" id="emergency">
        <div className="left-consult">
            <div className="dash-c"></div>
            <div className="section-consult-description">
                <h1>Emergency Preparedness</h1>
                <p>
                When emergences strike, they result into workplace injuries, illnesses and some times death if they are not well dealt with. 
                <br/>
                <br/>
                At DIOSH we  help you design Emergence response plans,planning in advance helps ensure that every one knows what to do when an emergenc occurs. Services herein include Fire protection systems, handling of hazardous materials, medical and first aid services etc
                </p>
            </div>    
        </div> 
      <div className="right-consult">
        <div className="consult-img-list">
            <img src={emergency} alt='emergency' />


        </div>
        </div>
        
    </div>
  );
};

export default Manuals;
