import React from 'react';
import './Hssetraining.css';
import safetytraining from '../assets/safetytraining.jpg';

const Hssetraining = () => {
  return (
    <div className="section-hssetraining" id="hsse-training">
      <div className="left-section">
        <div className="dash-x"></div>
        <div className="section-hssetraining-description">
          <h1>HSSE Training</h1>
          <p>
            We work closely with the Ministry of Gender Labour & Social Development
            of Uganda (MGLSD) officials and with their advice to deliver Training on
            Occupational Health and Safety (OSH) in Uganda that enables workplaces to
            reduce illnesses or injuries caused by working conditions.
            <br />
            <br />
            The training provides a clear understanding of Occupational Safety & Health
            (OSH) widely neglected in many African countries, where industrial activity
            has increased, but the health and safety of workers are scarcely tackled.
            <br />
            <br />
            By the end of this training programme, your staff can understand the interaction
            between the actors within occupational health, including employers, workers,
            health & safety staff, and the government.
            <br />
            <br />
            After the course, you will understand the basic concepts of occupational safety
            & health, tasks, and structure of occupational safety & health services, risk factors
            for illness and injuries at workplaces. 
          
          </p>
        </div>
      </div>
      <div className="right-section">
        <div className="section-img-list">
          <img src={safetytraining} alt='safety-training' />
          <div className="list-heading">
            Under this training you will learn about; 
          </div>
          <div className="list-trainings">
            <ul>
              <li>The major occupational diseases.</li>
              <li>The association between workplace exposure and health effects.</li>
              <li>The main steps in preventing exposure to health hazards in the work environment.</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Hssetraining;
