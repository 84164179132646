import React from 'react';
import './Services.css';

import hselogo from '../assets/hselogo.png';
import oshpolicy from '../assets/oshpolicy.png';
import supportcontracts from '../assets/supportcontracts.png';
import workplaceinspections from '../assets/workplaceinspections.png';
import hsseaudits from '../assets/hseaudits.png';
import riskassesments from '../assets/riskassesments.png';
import hssemanuals from '../assets/hssemanuals.png';
import emergencypreparedness from '../assets/emergencypreparedness.png';

const Services = () => {
  const servicesData = [
    { id: 'hsse-training', title: 'HSSE<br/>Training', imgSrc: hselogo, alt: 'HSE Logo' },
    { id: 'osh-policy', title: 'OSH Policy Formulation', imgSrc: oshpolicy, alt: 'OSH Policy' },
    { id: 'support-contracts', title: 'Support<br/> Contracts', imgSrc: supportcontracts, alt: 'Support Contracts' },
    { id: 'workplace-inspections', title: 'Workplace Inspections', imgSrc: workplaceinspections, alt: 'Workplace Inspections' },
    { id: 'hsse-audits', title: 'HSSE<br/>Audits', imgSrc: hsseaudits, alt: 'HSE Audits' },
    { id: 'risk-assessments', title: 'Risk <br/>Assessments', imgSrc: riskassesments, alt: 'Risk Assessments' },
    { id: 'hsse-manuals', title: 'HSSE<br/>Manuals', imgSrc: hssemanuals, alt: 'HSE Manuals' },
    { id: 'emergency', title: 'Emergency Preparedness', imgSrc: emergencypreparedness, alt: 'Emergency Preparedness' },
  ];

  return (
    <div className="services-place-holder">
      <div className="section-header-services">
        <div className="dash-1"></div>
        <h1>Our Services</h1>
        <div className="dash-2"></div>
      </div>
      <div className="section-services-list">
        {servicesData.map((service, index) => (
          <div className="pallet" key={index}>
            <img src={service.imgSrc} alt={service.alt} />
            {/* Use dangerouslySetInnerHTML for line breaks */}
            <p dangerouslySetInnerHTML={{ __html: service.title }} />
            <a href={`#${service.id}`} className="read-more">Read More..</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
